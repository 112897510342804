import React, {useEffect, useMemo} from 'react';
import PropTypes from 'prop-types';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import {useHistory} from "react-router-dom";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`nav-tabpanel-${index}`}
            aria-labelledby={`nav-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `nav-tab-${index}`,
        'aria-controls': `nav-tabpanel-${index}`,
    };
}

export default function NavTabs(props) {
    const history = useHistory();
    const [value, setValue] = React.useState(0);

    const navtabs = useMemo(() => [
        {label: "Einföld", url: "/"},
        {label: "Ítarleg", url: "/advanced"},
        {label: "Vaxtatafla", url: "/vextir-ahrif"},
    ], [])

    useEffect(() => {
        const currentPath = history.location.pathname;
        let idx = 1;
        for (let i = 0; i < navtabs.length; i++) {
            if (navtabs[i].url === currentPath) {
                idx = i;
                break;
            }
        }
        setValue(idx);
        }, [history.location.pathname, navtabs]
    )

    const handleChange = (event, newValue) => {
        // console.log(event);
        // console.log(newValue);
        setValue(newValue);
    };



    return (
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="navigation tabs"
                >
                    {navtabs.map((tab, idx) =>
                    <Tab key={tab.url}
                        component="a"
                        label={tab.label}
                        onClick={() => history.push(tab.url)}
                            {...a11yProps(idx)}

                    />
                    )}
                    {/*<Tab*/}
                    {/*    component="a"*/}
                    {/*    label="Page One" onClick={() => history.push("/")}*/}
                    {/*    {...a11yProps(0)}*/}
                    {/*/>*/}
                    {/*<Tab*/}
                    {/*    component="a"*/}
                    {/*    label="Page Two" onClick={() => history.push("/advanced")}*/}
                    {/*    {...a11yProps(1)}*/}
                    {/*/>*/}
                </Tabs>
    );
}