const utils = {}

utils.isNumber = (x) => typeof (x) === "number";
utils.clamp = (num, min, max) => Math.min(Math.max(num, min), max);


/**
 * @param {number} principal - the total amount borrowed
 * @param interest - e.g. 0.02 for 2%
 * @param paymentsLeft - e.g. 480 for 40 years with a payment each month
 * @return {number} - the total amount to pay
 */
utils.amortizedPayment = (principal, interest, paymentsLeft) => {
    if (interest === 0) return principal / paymentsLeft;

    let r = interest + 1;
    let interestTot = Math.pow(r, paymentsLeft);
    return (principal * interest * interestTot) / (interestTot - 1);
};

/**
 *
 * @param nominal_interest: usually the annual interest, e.g. 0.04 for 4%
 * @param compounding_freq: e.g. 12 for compounding each month
 * @returns {number} The "real" interest applied each period
 */
utils.compoundInterest = (nominal_interest, compounding_freq) => {
    return Math.exp(Math.log(1 + nominal_interest) / compounding_freq) - 1;
}


utils.calcPaymentPlan = (
    loanInfo,
    overridePayments = []
) => {
    // const baseMonthlyInterest = loanInfo.interest / 12 / 100;
    const numPayments = loanInfo.nYears * 12;
    // const monthlyInflation = utils.compoundInterest(loanInfo.inflation / 100, 12);

    const extraPaymentMonthly = loanInfo.monthlyExtraPayment;
    const monthlyStaticPayment = loanInfo.monthlyStaticPayment;

    // TODO how to handle if both extraMontlyPayment & monthlyStatic payments are given
    //      should this be allowed??

    let meta = []
    for (let i = 0; i < numPayments; i++) {
        meta[i] = {}
    }
    overridePayments.forEach(d => {
        meta[d.idx] = d;
    })

    let paymentPlan = [];
    const isEqualPayment = loanInfo.paymentType === "equalPayment";
    const isInflationAdjusted = loanInfo.loanType === "inflationAdjusted";

    // let cumulativeCompoundedInflation = 1 + monthlyInflation;

    for (let i = 0; i < numPayments; i++) {
        let paymentsLeft = numPayments - i;

        let payment = {};
        payment.paymentNum = i + 1;

        // payment.principalBefore = paymentPlan[i - 1]?.principalAfter || principal;
        payment.principalBefore = (i === 0) ? loanInfo.principal : paymentPlan[i - 1].principalAfter;
        payment.processingFee = (payment.principalBefore > 0) ? loanInfo.processingFee : 0;  // no processing fee if done paying loan


        // override e.g. extra payments, interest, etc. on a monthly basis
        // (still yearly percentages, just on a monthly basis)
        payment.inflationPerc = loanInfo.inflation;
        payment.interestPerc = loanInfo.interest;
        Object.assign(payment, meta[i]);

        const monthlyInflation = utils.compoundInterest(payment.inflationPerc / 100, 12);

        payment.indexation = 0;
        if (isInflationAdjusted) {
            // payment.indexation = Math.round(monthlyInflation * payment.principalBefore);
            payment.indexation = monthlyInflation * payment.principalBefore;
        }
        payment.indexation = Math.round(payment.indexation);
        payment.principalBefore += payment.indexation;


        // ================================================
        const monthlyInterest = payment.interestPerc / 12 / 100;

        // === only used for equal payment amortized loans (jafgreidslulan)
        let periodicPaymentAmount;
        periodicPaymentAmount = utils.amortizedPayment(
            payment.principalBefore, monthlyInterest, paymentsLeft
        )
        periodicPaymentAmount = Math.round(periodicPaymentAmount);
        // =================================================================

        payment.towardsInterest = Math.round(monthlyInterest * payment.principalBefore);
        // payment.towardsInterest = monthlyInterest * payment.principalBefore;

        // ===========================================
        // ==== Base towards principal ===============
        let towardsPrincipalAmortized;
        if (isEqualPayment)
            towardsPrincipalAmortized = periodicPaymentAmount - payment.towardsInterest;
        else
            // towardsPrincipalAmortized = Math.round(payment.principalBefore / paymentsLeft);
            towardsPrincipalAmortized = payment.principalBefore / paymentsLeft;

        towardsPrincipalAmortized = Math.round(towardsPrincipalAmortized);

        // ===========================================
        // ========= handle extra payments ===========
        let extraPayment;
        if (meta[i].extraPayment !== undefined) {
            // if override present just use that and do nothing more
            // payment.extraPayment = meta[i].extraPayment;
            extraPayment = meta[i].extraPayment;
        } else {
            // if override is not present, then handle extra payment and/or monthlyStaticPayment amount
            extraPayment = utils.isNumber(extraPaymentMonthly) ? extraPaymentMonthly : 0;
            extraPayment = utils.clamp(extraPayment, 0, payment.principalBefore - towardsPrincipalAmortized);

            let totalAmount = towardsPrincipalAmortized + payment.towardsInterest + extraPayment + payment.processingFee;

            if (monthlyStaticPayment) {
                let staticAmount = utils.isNumber(monthlyStaticPayment) ? monthlyStaticPayment : 0;
                if (staticAmount > totalAmount) {
                    let diff = staticAmount - totalAmount;
                    diff = utils.clamp(diff, 0, payment.principalBefore - (towardsPrincipalAmortized + extraPayment));
                    extraPayment += diff;
                }
            }
        }

        // payment.extraPayment = extraPayment;
        payment.extraPayment = utils.clamp(extraPayment, 0, payment.principalBefore - towardsPrincipalAmortized);

        payment.towardsPrincipal = towardsPrincipalAmortized;
        payment.principalAfter = payment.principalBefore - payment.towardsPrincipal - payment.extraPayment;
        payment.totalAmount = payment.towardsInterest + payment.towardsPrincipal + payment.extraPayment + payment.processingFee;


        // =================================================
        // === Calculate present value of the Total amount

        // This may seem unessecarily complex, but will come in handy
        // when we allow editing the inflation on a per monthly basis
        let lastCCI;
        if (i === 0) lastCCI = 1 + monthlyInflation;
        else lastCCI = paymentPlan[i - 1].cumulativeCompoundedInflation;
        payment.cumulativeCompoundedInflation = lastCCI * (1 + monthlyInflation);

        // payment.presentValueTotal = Math.round(payment.totalAmount / payment.cumulativeCompoundedInflation);
        payment.presentValueTotal = payment.totalAmount / payment.cumulativeCompoundedInflation;
        // payment.presentValueTotal = Math.round(payment.totalAmount / Math.pow((1 + monthlyInflation), i+1));

        // PV debug/verify
        // payment.pvDiff = payment.totalAmount - payment.presentValueTotal;
        // if (i === 0) {
        //     payment.pvFromInit = 0
        //     payment.pvDiffIncrease = payment.pvDiff;
        // }
        // else {
        //     payment.pvDiffIncrease = payment.pvDiff - paymentPlan[i-1].pvDiff;
        //     payment.pvFromInit = payment.presentValueTotal - paymentPlan[0].presentValueTotal;
        // }


        paymentPlan[i] = payment;
    }

    return paymentPlan;
}


export default utils;