import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import firebase from "firebase";
import 'firebase/analytics'

export default function Analytics() {
    const isLocalhost = window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1";
    const location = useLocation();
    firebase.analytics();

    useEffect(() => {
        const analytics = firebase && firebase.analytics;
        if (!isLocalhost && typeof analytics === "function") {
            const currentPage = location.pathname + location.search;
            analytics().setCurrentScreen(currentPage);
            analytics().logEvent("page_view", { page_path: currentPage });
        }
    }, [location, isLocalhost]);

    return null;
}
