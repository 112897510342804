const hostname = window && window.location && window.location.hostname;


const firebaseWrapper = {
    firebaseConfig: () => {

        const prodConfig = {
            apiKey: "AIzaSyBLUAMMIZyImUfk1h8f5djNuc8JQ0H-acE",
            authDomain: "loan-calc-prod.firebaseapp.com",
            projectId: "loan-calc-prod",
            storageBucket: "loan-calc-prod.appspot.com",
            messagingSenderId: "511852539398",
            appId: "1:511852539398:web:9ecadb0af27fe79bdd5d87",
            measurementId: "G-STM0F09PXJ"

        };

        const stagingConfig = {
            apiKey: "AIzaSyBhTlaqYhcm0vueeNiHYZ0in_HGrMzXJlY",
            authDomain: "loan-calc-bf7ac.firebaseapp.com",
            projectId: "loan-calc-bf7ac",
            storageBucket: "loan-calc-bf7ac.appspot.com",
            messagingSenderId: "791636302443",
            appId: "1:791636302443:web:5ac4c747db7996cb7daf2e",
            measurementId: "G-CKQFLKNYX6"
        };

        if (hostname === "lanareiknivel.is" ||
            hostname === "www.lanareiknivel.is" ||
            // hostname === "loan-calc-bf7ac.firebaseapp.com"
            hostname === "loan-calc-prod.web.app" ||
            hostname === "loan-calc-prod.firebaseapp.com"
        )
            return prodConfig;
        else
            return stagingConfig;
    }
}


export default firebaseWrapper;