import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
    formControl: {
        // margin: theme.spacing(1),
        minWidth: 120,
    },
}));

export default function NativeSelects(props) {
    const classes = useStyles();

    const {value, changeFun} = props;

    const handleChange = (event) => {
        const val = event.target.value;
        changeFun(val);
    };

    return (
        <div>
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="payment-type-select">Greiðslumáti</InputLabel>
                <Select
                    native
                    // value={state.age}
                    value={value}
                    onChange={handleChange}
                    label="Payment type"
                    inputProps={{
                        name: 'paymentTypeName',
                        id: 'payment-type-select',
                    }}
                >
                    {/*<option aria-label="None" value="" />*/}
                    <option value={"equalPayment"}>Jafnar greiðslur</option>
                    <option value={"equalPrincipalPayment"}>Jafnar afborganir</option>
                </Select>
            </FormControl>
        </div>
    );
}