/**
 * Filter & control product view
 */
import React from 'react';
import Plotly from 'plotly.js-basic-dist';
import createPlotlyComponent from 'react-plotly.js/factory';
import {useSelector} from "react-redux";
import {selectSummaryTableData} from "../../selectors/summaryTableSelector";

const Plot = createPlotlyComponent(Plotly);

const PaymentSplitChart = (props) => {
    // const {title, selectKey} = props;
    const {title, usePresentValue} = props;

    const summary = useSelector(selectSummaryTableData);
    // const [summary] = useDebounce(_summary, 400);

    const titleConfig = {
        text: title,
        font: {
            family: 'Courier New, monospace',
            size: 24
        },
        xref: 'paper',
        x: 0.025,
        y: 0.95
    };

    const xAxisConfig = {
        title: {
            text: 'Greiðsla nr.',
            font: {
                family: 'Courier New, monospace',
                size: 18,
                color: '#7f7f7f',
            },
            standoff: 10,
        }
    };

    let labels = [
        "Höfuðstóll",
        "Vextir + gjöld",
        "Verðbætur",

    ];

    let data;
    if (usePresentValue) {
        data = [
            {label: labels[0], val: summary.totalPrincipalPV},
            {label: labels[1], val: summary.totalInterestAndFeesPV},
            {label: labels[2], val: summary.totalIndexationPV},
        ];
    } else {
        data = [
            {label: labels[0], val: summary.totalTowardsPrincipal},
            {label: labels[1], val: summary.totalTowardsInterest + summary.totalProcessingFee},
            {label: labels[2], val: summary.totalIndexation},
        ];
    }

    data = data.filter(d => d.val !== 0);

    const values = data.map(d => Math.round(d.val));
    labels = data.map(d => d.label);
    // const colors = [
    //     'rgb(31, 119, 180, 0.8)',
    //     'rgb(255, 127, 14, 0.8)',
    //     'rgb(44, 160, 44, 0.8)'
    // ]

    const plotData = [{
        values: values,
        labels: labels,
        type: 'pie',
        sort: false,
        // marker: {
        //     colors: colors
        // }
    }];

    // const layout = {
    //     height: 400,
    //     // width: 500
    // };


    return (
        <>
            <Plot
                data={plotData}
                layout={{
                    margin: {
                        t: 50, r: 20, b: 40, l: 40,
                        pad: 5
                    },
                    // height: layout.height,
                    // width: layout.width,
                    separators: ',.',
                    title: titleConfig,
                    xaxis: xAxisConfig
                }}
                config={{displayModeBar: false}}
                useResizeHandler={true}
                style={{width: "100%", height: "100%"}}
            />
        </>
    );
}

export default PaymentSplitChart;
