/**
 * Filter & control product view
 */
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import React, { useEffect, useState, useCallback } from 'react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine-dark.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import "./tables.css";

import { useDispatch, useSelector } from "react-redux";
import { selectYearlyPaymentPlan1 } from "../../selectors/yearlyPaymentPlanSelector";
import { getPaymentPlan1, setPaymentOverride } from "../../slices/controlSlice";
import NavSwitchYearly from "./NavSwitchYearly";
import NumericEditor from "./NumericCellEditor";


const editableCellStyle = {
    "background-color": "rgb(255, 255, 255)",
    "cursor": "text",
    "outline": "1px rgb(113, 158, 206) inset",
    "outline-offset": "-2px"  /* negative border width + outline width */

}

const LoanTable = () => {
    const dispatch = useDispatch();

    const [displayYearly, setDisplayYearly] = useState(false);

    const dataSelector = displayYearly ? selectYearlyPaymentPlan1 : getPaymentPlan1;

    // const rowData = useSelector(getPaymentPlan1);
    let rowData = useSelector(dataSelector);

    const [gridApi, setGridApi] = useState(null);
    // const [gridColumnApi, setGridColumnApi] = useState(null);

    const formatter = new Intl.NumberFormat('de-DE');
    const formatThousandSep = function(params, round=true) {
        let val = params.value;
        if (round) val = Math.round(val);
        return formatter.format(val);
    }

    const sizeToFit = useCallback(() => {
        if (gridApi) {
            gridApi.sizeColumnsToFit();
        }
    }, [gridApi]);

    useEffect(() => {
        setTimeout(() => {
            window.addEventListener("resize", sizeToFit);
        });
        return () => window.removeEventListener("resize", sizeToFit);
    }, [sizeToFit]);

    const onGridReady = (params) => {
        setGridApi(params.api);
        // setGridColumnApi(params.columnApi);
    };

    const handleFirstDataRender = (params) => {
        sizeToFit();
    }

    /**
     * Generic override value in paymentPlan based on the field
     * does not work per year
     * */
    const setCellValue = (params, overrideField) => {
        let overrideData = [];
        if (displayYearly) {
            // should override my idx and the 11 after (for a total of 12 months)
            const beginIdx = params.node.rowIndex * 12;
            const maxIdx = params.node.rowIndex * 12 + 12;
            for (let i = beginIdx; i < maxIdx; i++) {
                const cellData = {
                    idx: i,
                    value: params.newValue,
                    field: overrideField
                }
                overrideData.push(cellData);
            }
        } else {
            const cellData = {
                idx: params.node.rowIndex,
                value: params.newValue,
                // field: params.colDef.field,
                field: overrideField,
            }
            overrideData.push(cellData);
        }

        // cellData[params.colDef.field] =
        // const field = params.colDef.field;
        dispatch(setPaymentOverride(
            overrideData
        ));
    }


    const colMetadata = {
        paymentNum: {
            header: displayYearly ? "Ár" : "#"
        },
        extraPayment:
            {
                header: displayYearly ? "Aukagr. (mánaðarleg)" : "Aukagr.",
                field: displayYearly ? "averageExtraPayment" : "extraPayment"
            },
    }

    const handleYearChange = (value) => {
        setDisplayYearly(value === 1);
    };


    return (
        <>
            <NavSwitchYearly
                changeFun={handleYearChange}
                initialValue={0}
            />

            <div className="ag-theme-alpine"
                 style={{
                     height: "75vh",
                 }}>
                <AgGridReact
                    rowData={rowData}
                    applyColumnDefOrder={true}
                    onGridReady={onGridReady}
                    onFirstDataRendered={handleFirstDataRender}
                    immutableData={true}
                    getRowNodeId={(d) => {
                        return d.paymentNum;
                    }}
                    // suppressMultiRangeSelection={true}
                    // enableRangeSelection={true}
                    // stopEditingWhenGridLosesFocus={true}
                    frameworkComponents={{
                        numericEditor: NumericEditor
                    }}
                    defaultColDef={{
                        flex: 1,
                        minWidth: 70,
                        resizable: true
                    }}
                    gridOptions={{singleClickEdit: true}}
                >
                    <AgGridColumn
                        field="paymentNum"
                        headerName={colMetadata.paymentNum.header}
                        initialWidth={50}
                        minWidth={60}
                        // flex={50}
                    />
                    <AgGridColumn
                        field="principalBefore"
                        headerName="Höfuðstóll"
                        valueFormatter={formatThousandSep}
                        minWidth={110}
                    />
                    <AgGridColumn
                        // style={{cursor: "pointer"}}
                        field="totalAmount"
                        headerName="Heildargr."
                        valueFormatter={formatThousandSep}
                        minWidth={100}
                    />
                    <AgGridColumn
                        field="presentValueTotal"
                        headerName="Núvirði heildargr."
                        valueFormatter={formatThousandSep}
                        minWidth={100}
                    />

                    {/*PV debug/verify*/}
                    {/*<AgGridColumn*/}
                    {/*    field="pvDiff"*/}
                    {/*    headerName="Mismunur"*/}
                    {/*    valueFormatter={formatThousandSep}*/}
                    {/*    minWidth={70}*/}
                    {/*/>*/}
                    {/*<AgGridColumn*/}
                    {/*    field="pvDiffIncrease"*/}
                    {/*    headerName="Mismunur Eykst"*/}
                    {/*    valueFormatter={formatThousandSep}*/}
                    {/*    minWidth={70}*/}
                    {/*/>*/}
                    {/*<AgGridColumn*/}
                    {/*    field="pvFromInit"*/}
                    {/*    headerName="Mismunur fra gr. 1"*/}
                    {/*    valueFormatter={formatThousandSep}*/}
                    {/*    minWidth={70}*/}
                    {/*/>*/}

                    <AgGridColumn
                        field="towardsPrincipal"
                        headerName="Afborgun"
                        valueFormatter={formatThousandSep}
                        minWidth={100}
                    />
                    <AgGridColumn
                        field="towardsInterest"
                        headerName="Vextir"
                        valueFormatter={formatThousandSep}
                        minWidth={90}
                    />
                    <AgGridColumn
                        field="indexation"
                        headerName="Verðbætur"
                        valueFormatter={formatThousandSep}
                        minWidth={100}

                    />
                    <AgGridColumn
                        field="processingFee"
                        headerName="Gjöld"
                        valueFormatter={formatThousandSep}
                        minWidth={80}
                    />
                    <AgGridColumn
                        field="principalAfter"
                        headerName="Eftirstöðvar"
                        valueFormatter={formatThousandSep}
                        minWidth={100}
                    />
                    <AgGridColumn
                        field={colMetadata.extraPayment.field}
                        headerName={colMetadata.extraPayment.header}
                        valueFormatter={formatThousandSep}
                        editable={true}
                        cellEditor="numericEditor"
                        valueSetter={(params) => setCellValue(params, "extraPayment")}
                        cellStyle={editableCellStyle}
                        minWidth={100}
                        // onCellValueChanged={params => {console.log(params)}}
                    />
                    <AgGridColumn
                        field="interestPerc"
                        headerName="Vextir (%)"
                        valueFormatter={(params) =>formatThousandSep(params, false)}
                        editable={true}
                        cellEditor="numericEditor"
                        valueSetter={(params) => setCellValue(params, "interestPerc")}
                        cellStyle={editableCellStyle}
                        minWidth={110}
                        // onCellValueChanged={params => {console.log(params)}}
                    />
                    <AgGridColumn
                        field="inflationPerc"
                        headerName="Verðbólga (%)"
                        valueFormatter={(params) =>formatThousandSep(params, false)}
                        editable={true}
                        cellEditor="numericEditor"
                        valueSetter={(params) => setCellValue(params, "inflationPerc")}
                        cellStyle={editableCellStyle}
                        minWidth={100}
                    />
                </AgGridReact>
            </div>
        </>
    );
};

export default LoanTable;
