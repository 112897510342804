import {createSelector} from "@reduxjs/toolkit";
import {getPaymentPlan1} from "../slices/controlSlice";
import _sumBy from "lodash/sumBy"
import _meanBy from "lodash/meanBy"


export const selectYearlyPaymentPlan1 = createSelector(
    [
        getPaymentPlan1
    ], (paymentPlan) => {
        let res = [];

        const nYears = paymentPlan.length / 12;

        for (let i = 0; i < nYears; i++) {
            let paymentsThisYear = paymentPlan.slice(i * 12, i * 12 + 12);
            let yearSummary = {
                extraPaymentYearly: _sumBy(paymentsThisYear, d => d.extraPayment),
                averageExtraPayment: _meanBy(paymentsThisYear, d => d.extraPayment),
                indexation: _sumBy(paymentsThisYear, d => d.indexation),
                presentValueTotal: _sumBy(paymentsThisYear, d => d.presentValueTotal),
                processingFee: _sumBy(paymentsThisYear, d => d.processingFee),
                totalAmount: _sumBy(paymentsThisYear, d => d.totalAmount),
                towardsInterest: _sumBy(paymentsThisYear, d => d.towardsInterest),
                towardsPrincipal: _sumBy(paymentsThisYear, d => d.towardsPrincipal),
                averageTotalPayment: _meanBy(paymentsThisYear, d => d.totalAmount),
                interestPerc: _meanBy(paymentsThisYear, d => d.interestPerc),
                inflationPerc: _meanBy(paymentsThisYear, d => d.inflationPerc),
            }

            yearSummary.yearNum = i+1;
            yearSummary.paymentNum = i+1;
            yearSummary.principalBefore = paymentsThisYear[0].principalBefore;
            yearSummary.principalAfter = paymentsThisYear[11].principalAfter;

            res.push(yearSummary);
        }

        return res;

    }
)
