/**
 * Filter & control product view
 */
import React from 'react';


import 'ag-grid-community/dist/styles/ag-grid.css';
// import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";

// const useStyles = makeStyles((theme) => ({
//     root: {
//         // flexGrow: 1,
//         // maxWidth: 350,
//     },
//     demo: {
//         backgroundColor: theme.palette.background.paper,
//     },
//     title: {
//         margin: theme.spacing(2, 0, 0),
//     },
// }));


const SummaryTableItem = (props) => {
    // const {summarySelector} = props;
    const {label, value} = props;
    let {styleOverride} = props;
    styleOverride = styleOverride || {};

    return (
        <>
            <ListItem>
                {/*<strong>*/}
                <ListItemText
                    // style={{fontWeight: "bold"}}
                    primaryTypographyProps={{style: {...styleOverride}}}
                    primary={label}
                />
                <ListItemSecondaryAction style={{...styleOverride}}>
                    {value}
                </ListItemSecondaryAction>
                {/*</strong>*/}
            </ListItem>
            <Divider/>
        </>
    );
}

export default SummaryTableItem;
