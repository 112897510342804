import FormControl from '@material-ui/core/FormControl';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import { makeStyles } from '@material-ui/core/styles';
import _uniqueId from 'lodash/uniqueId';
import React from 'react';
import NumberFormat from 'react-number-format';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    margin: {
        margin: theme.spacing(1),
    },
}));

function WrappedNumberInput(props) {
    const classes = useStyles();

    const {label, adornment, value, changeFun, min, max} = props;

    const handleOnValueChange = (valuesObj, e) => {
        changeFun(valuesObj.floatValue)
    }

    const checkIfAllowed = (values) => {
        const val = values.floatValue;
        if (val < min) return false;
        if (val > max) return false;

        return true
    }

    const labelId = _uniqueId();

    // Note it seems I can just pass any props that
    // material ui's Input component would use to NumberFormat
    // i.e. it seems all props are forwarded to the customInput component
    return (
        <FormControl className={classes.margin}>
            <InputLabel htmlFor={labelId}>
                {label}
            </InputLabel>
            <NumberFormat
                customInput={Input}
                isAllowed={checkIfAllowed}
                // customInput={NumberInput}
                endAdornment={<InputAdornment position="end">{adornment}</InputAdornment>}
                // prefix={'$'}
                // format={format || null}
                // type="number"
                // displayType="text"
                // type="number"
                value={value}
                onValueChange={handleOnValueChange}
                thousandSeparator={"."}
                decimalSeparator={","}
                // type="number"
                isNumericString={true}
                // inputProps={{
                //     type: "number"
                // }}
            />
        </FormControl>
    );
}


export default WrappedNumberInput;
// export default NumberInput;
