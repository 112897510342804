import { configureStore } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import controlStore from "../slices/controlSlice";
import dataStore from "../slices/dataSlice"


export default configureStore({
  reducer: {
    counter: counterReducer,
    controlStore: controlStore,
    dataStore: dataStore
  },
});
