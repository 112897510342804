import { Accordion, AccordionDetails, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React from 'react';
import { getLoan1, setLoan1 } from "../../slices/controlSlice";
import LoanTableSimple from "../ag_grid/LoanTableSimple";
import LoanChart from "../charts/LoanChart";
import LoanForm from "../loan_form/LoanForm";
import StyledAccordionSummary from '../partials/StyledAccordionSummary';
import SummaryTable from "../summaryTable/SummaryTable";


const useStyles = makeStyles((theme) => ({
    title: {
        marginBottom: "12px",
        fontSize: "2.5rem",
        ['@media (max-width:1280px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: "2rem",
        },
        ['@media (max-width:940px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: "2rem",
            // marginLeft: "-5em"
        },
        ['@media (max-width:420px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: "2rem",
            // marginLeft: "-2em"
        },
    },
    summary: {
        marginTop: theme.spacing(2),
        // maxWidth: "369px",
        ['@media (max-width:940px)']: { // eslint-disable-line no-useless-computed-key
            marginTop: theme.spacing(5),
        },
    },
    loanInput: {
        marginTop: theme.spacing(2),
        // maxWidth: "420px",
        ['@media (max-width:940px)']: { // eslint-disable-line no-useless-computed-key
            marginTop: theme.spacing(5),
        },
    },
    chart: {
        marginTop: theme.spacing(6),
        // maxHeight: "350px",
        ['@media (max-width:940px)']: { // eslint-disable-line no-useless-computed-key
            //     paddingLeft: "-5vw",
            //     width: "90vw",
        },
    },
    table: {
        marginTop: theme.spacing(7),
    },
    chartTop: {
        // marginTop: theme.spacing(6),
        // maxHeight: "350px",
        ['@media (max-width:940px)']: { // eslint-disable-line no-useless-computed-key
            // paddingLeft: "-5vw",
            // width: "90vw",
            // paddingBottom: -100,
        },
    }
}));

function SimplePage() {
    const classes = useStyles();
    const centerContainerProps = {xs: 12, md: 9, xl: 7}

    return (
        <Grid container justify="center">

            <Grid container item alignItems="center"
                  justify="center"
                  xs={12} lg={10}
            >

                <Grid item container direction="column"
                    // justify='center'
                      {...centerContainerProps}
                >
                    {/*====== Container for keeping charts square with container above =========*/}
                    <Grid container item
                          direction="column"
                          alignItems="center"
                          justify="center"
                        // spacing={2}
                    >
                        <Grid container item
                              justify="center"

                        >
                            <Grid item style={{marginTop: "2em", marginBottom: "2em", paddingLeft: "8px"}}
                                  xs={12} sm={8} md={12}
                            >
                                <Typography className={classes.title} variant="h1">
                                    Almenn lánareiknivél fyrir íbúðalán
                                </Typography>

                                <Typography>
                                    Hér getur þú slegið inn forsendur láns og áætlað mánaðarlega og árlega
                                    greiðslubyrði.
                                </Typography>
                                <Typography>
                                    Þú færð einnig áætlað núvirði miðað við verðbólguspá, sem
                                    getur hjálpað þér að bera saman mismunandi forsendur lána,
                                    t.d. verðtryggð og óverðtryggð lán.
                                </Typography>
                                <Typography>
                                    Fyrir aukagreiðslur og fleiri möguleika getur þú skoðað <a
                                    href="/advanced">ítarlega</a> lánareiknirinn okkar.
                                </Typography>
                            </Grid>
                        </Grid>


                        {/*====== Loan input form and summary table =========*/}
                        <Grid container item
                              justify="center"
                              xs={12}
                              spacing={2}
                        >
                            <Grid className={classes.loanInput} item xs={12} sm={8} md={6} lg={7}>
                                <LoanForm
                                    title="Lán"
                                    getLoanFun={getLoan1}
                                    setLoanFun={setLoan1}
                                    showAdvanced={false}
                                />
                            </Grid>

                            <Grid className={classes.summary} item xs={12} sm={8} md={6} lg={5}>
                                <SummaryTable
                                    showAdvanced={false}
                                />
                            </Grid>
                        </Grid>

                        {/*====== Charts =========*/}
                        <Grid container item justify="center" style={{ marginBottom: '12px', marginLeft: '8px', marginRight: '8px'}}
                        >
                            <Accordion style={{ width: '100%', marginTop: "1.5em"}} defaultExpanded>
                                <StyledAccordionSummary>
                                    <Typography style={{ textAlign: "center" }}>Þróun höfuðstóls & greiðslubyrðar</Typography>
                                </StyledAccordionSummary>
                                <AccordionDetails>
                                    <Grid container justify="center">
                                        <Grid item className={classes.chart} xs={12} sm={12} md={6}>
                                            <LoanChart
                                                title="Þróun höfuðstóls"
                                                selectKey={"principalBefore"}
                                                hoverLabel="Höfuðstóll"
                                            />
                                        </Grid>
                                        <Grid item className={classes.chart} xs={12} sm={12} md={6}>
                                            <LoanChart
                                                title="Þróun greiðslubyrðar"
                                                selectKey={"totalAmount"}
                                                hoverLabel="Greiðsla"
                                            />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>


                    </Grid>
                </Grid>
            </Grid>

            <Grid className={classes.table} container justify="center">
                <Grid item xs={12} md={10} xl={8}>
                    {/*<Grid xs={12} item>*/}
                    {/*    <Typography variant="h4">Greiðsluáætlun</Typography>*/}
                    {/*</Grid>*/}
                    <Grid xs={12} item>
                        <LoanTableSimple/>
                    </Grid>
                    {/*<Grid xs={12} item>*/}
                    {/*    <Typography>*/}
                    {/*        Einföld lánareiknivél*/}
                    {/*    </Typography>*/}
                    {/*</Grid>*/}
                </Grid>
            </Grid>

            {/*<CenterGrid*/}
            {/*    centerWidths={{lg: 10, xl: 8}}*/}
            {/*>*/}
            {/*    /!*<Typography variant="h4">Greiðsluáætlun</Typography>*!/*/}
            {/*    <LoanTableSimple sizing={{lg: 9000}}></LoanTableSimple>*/}
            {/*</CenterGrid>*/}

        </Grid>
    );
}

export default SimplePage;
