import {createSlice} from '@reduxjs/toolkit';
import utils from "../javascripts/utils";

export const controlStore = createSlice({
    name: 'controlStore',
    initialState: {
        // plan to only have support for 2 loans,
        // so just do some code duplication for now
        // and refactor if this assumption changes later

        // ==================================
        // ========== LoanChart 1 ===========
        // ==================================
        loan1: {
            interest: 10.25,
            principal: 35000000,
            inflation: 8,
            nYears: 30,
            processingFee: 120,
            monthlyExtraPayment: 0,  // e.g. always pay 10K extra towards the principal
            monthlyStaticPayment: 0, // e.g. always pay 200K (assuming 200K >= the total payment amount.. so mininum payment really
            loanType: "normal", // inflation adjuster or not
            // isInflationAdjusted: false,
            paymentType: "equalPayment", // otherwise assume equal principal payment (not equal total payment)
        },

        get paymentPlan1() {
            return utils.calcPaymentPlan(
                this.loan1
        )
        },

        paymentOverrides: [],


        // =============================
        // ========== LoanChart 2 ===========
        // =============================
        loan2: {
            interest: 0,
            principal: 0,
            inflation: 0,
            nYears: 0,
            processingFee: 120,
            monthlyExtraPayment: 0,
            monthlyStaticPayment: 0,
        },

        paymentPlan2: []
    },
    reducers: {
        setLoan1: (state, action) => {
            // console.log("setting value for loan 1:", action.payload);
            Object.assign(state.loan1, action.payload);

            // clear all payment overrides on loan form change for now
            // TODO is this the right thing to do?
            // state.paymentOverrides = [];

            // console.log("state.loan1: ", state.loan1);
            state.paymentPlan1 = utils.calcPaymentPlan(
                state.loan1,
                state.paymentOverrides
            );

            // console.log('current state', current(state));
        },

        setPaymentOverride: (state, action) => {
            // 1. find already present override object (if applicable)
            // 2. set new value
            // 3. insert into list in correct order (or insert and sort)
            const payload = action.payload;

            let currentOverrides = state.paymentOverrides;

            for (let j = 0; j < payload.length; j++) {
                const override = payload[j];

                const i = currentOverrides.findIndex(
                    d => d.idx === override.idx
                );

                // undefined if i = -1
                let prevObj = currentOverrides[i];

                prevObj = prevObj || {}
                prevObj.idx = override.idx;
                prevObj[override.field] = override.value;

                if (i >= 0) {
                    currentOverrides[i] = prevObj;
                } else {
                    currentOverrides.push(prevObj);
                }
            }

            state.paymentOverrides = currentOverrides;

            state.paymentPlan1 = utils.calcPaymentPlan(
                state.loan1,
                state.paymentOverrides
            );
        },

        setLoan2: (state, action) => {
            Object.assign(state.loan2, action.payload);
        },
    }
});

// export const {setProductFilters, setPriceNow} = controlStore.actions;

export const getLoan1 = (state) => state.controlStore.loan1;
export const getLoan2 = (state) => state.controlStore.loan2;

export const getPaymentPlan1 = (state) => state.controlStore.paymentPlan1;
export const getPaymentPlan2 = (state) => state.controlStore.paymentPlan2;

export const getPaymentOverride = (state) => state.controlStore.paymentOverrides;

// ---
export const {setLoan1, setLoan2, setPaymentOverride} = controlStore.actions;

export default controlStore.reducer;
