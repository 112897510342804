import { makeStyles } from "@material-ui/core/styles";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { AgGridReact } from 'ag-grid-react';
import _sumBy from "lodash/sumBy";
import React, { useEffect, useState } from 'react';
// import colorUtils from '../../javascripts/colorUtils';
import utils from "../../javascripts/utils";
import './tables.css';


const getLoanTypes = (loanInfoBase, loanTypes) => {
    const loanTemplate = {
        principal: loanInfoBase.principal,
        inflation: loanInfoBase.inflation,
        nYears: loanInfoBase.nYears,
        monthlyExtraPayment: 0,
        monthlyStaticPayment: 0,
        processingFee: 120,
        interest: 8.25,
        loanType: "normal",
        paymentType: "equalPayment",
    }

    if (
        !loanTemplate.principal ||
        !loanTemplate.inflation ||
        !loanTemplate.nYears 
    ) return [];

    const interestRates = Array.from({ length: 29 }, (_, i) => 2 + (i * 0.5));
    const formattedData = new Map();

    interestRates.forEach(interestRate => {
        const key = interestRate.toString();

        loanTypes.forEach(loanTypeObj => {
            const loanInfo = { ...loanTemplate, loanType: loanTypeObj.loanType, paymentType: loanTypeObj.paymentType, interest: interestRate };
            const paymentPlan = utils.calcPaymentPlan(loanInfo);

            // initialize this interest rate if it hasn't been seen before
            if (!formattedData.has(key)) {
                formattedData.set(key, { interestRate: interestRate });
            }
            // console.log(paymentPlan)
            // add the loan type to the existing interest rate
            formattedData.get(key)[`${loanTypeObj.loanType}_${loanTypeObj.paymentType}`] = {
                firstPayment: paymentPlan[0].totalAmount,
                totalAmount: _sumBy(paymentPlan, 'totalAmount'),
                totalAmountPV: _sumBy(paymentPlan, 'presentValueTotal'),

                // paymentPlan: paymentPlan
            };
        });
    });

    // Convert the Map values to an array
    return Array.from(formattedData.values());
};

function HtmlHeader(props) {
    return (
        <div dangerouslySetInnerHTML={{ __html: props.column.colDef.headerName }}></div>
    );
}

const formatter = new Intl.NumberFormat('de-DE');

const formatThousandSep = params => {
    return formatter.format(Math.round(params.value));
}

const useStyles = makeStyles({
    grid: {
        // '& .ag-row': {
        //     backgroundColor: 'rgb(255, 255, 255)', 
        // },
        // '& .ag-row-odd .ag-cell[col-id="interestRate"]': {
        //     backgroundColor: 'rgb(232, 232, 255)', 
        // },
    },
});

const InterestEffectsTable = (props) => {
    const classes = useStyles();

    const loanInfoBase = props.loanInfoBase;

    const isNormal = props.loanInfoBase.loanType === "normal";
    
    const loanTypes = [
        { loanType: 'normal', paymentType: 'equalPayment', displayBase: "Óverðtryggt jafnar greiðslur" },
        { loanType: 'normal', paymentType: 'equalPrincipal', displayBase: "Óverðtryggt jafnar afborganir" },
        { loanType: 'inflationAdjusted', paymentType: 'equalPayment', displayBase: "Verðtryggt jafnar greiðslur" },
        { loanType: 'inflationAdjusted', paymentType: 'equalPrincipal', displayBase: "Verðtryggt jafnar afborganir" },
    ];

    const [gridApi, setGridApi] = useState(null);
    const [rowData, setRowData] = useState(getLoanTypes(loanInfoBase, loanTypes));

    useEffect(() => {
        if (!loanInfoBase) { return; }

        const newLoanTypes = getLoanTypes(loanInfoBase, loanTypes);
        setRowData(newLoanTypes);

        if (gridApi) {
            gridApi.refreshCells({ force: true });
        }
    }, [loanInfoBase]);



    const sizeToFit = () => {
        // console.log("---- size to fit")
        if (gridApi) {
            gridApi.sizeColumnsToFit();
        }
    };

    useEffect(() => {
        setTimeout(() => {
            sizeToFit();
        }, 25);
    }, [gridApi, isNormal]);


    useEffect(() => {
        window.addEventListener("resize", sizeToFit);
        return () => window.removeEventListener("resize", sizeToFit);
    }, [gridApi]);

    const onGridReady = params => {
        setGridApi(params.api);
        window.addEventListener("resize", sizeToFit);
    };

    const onFirstDataRendered = (params) => {
        if (gridApi) {
            gridApi.sizeColumnsToFit();
        }
    };

    // const selectedCellStyle = heatMapGradient;
    const noStyle = params => {};

    const normalCols = {
        equalPayment: [
        {
            headerName: "Fyrsta greiðsla",
            field: `normal_equalPayment.firstPayment`,
        },
        {
            headerName: "Greitt alls",
            field: `normal_equalPayment.totalAmount`,
        },
        {
            headerName: "Greitt alls - Núvirði",
            field: `normal_equalPayment.totalAmountPV`,
            cellStyle: {borderRight: '1px solid rgb(186, 191, 199)'}
        },
        ],
        equalPrincipal: [
        {
            headerName: "Fyrsta greiðsla",
            field: `normal_equalPrincipal.firstPayment`,
        },
        {
            headerName: "Greitt alls",
            field: `normal_equalPrincipal.totalAmount`,
        },
        {
            headerName: "Greitt alls - Núvirði",
            field: `normal_equalPrincipal.totalAmountPV`,
        },
    ]};


    const inflationAdjustedCols = {
        equalPayment: [
        {
            headerName: "Fyrsta greiðsla",
            field: `inflationAdjusted_equalPayment.firstPayment`,
        },
        {
            headerName: "Greitt alls",
            field: `inflationAdjusted_equalPayment.totalAmount`,
        },
        {
            headerName: "Greitt alls - Núvirði",
            field: `inflationAdjusted_equalPayment.totalAmountPV`,
            cellStyle: {borderRight: '1px solid'}
        },
    ],
      equalPrincipal: [ {
            headerName: "Fyrsta greiðsla",
            field: `inflationAdjusted_equalPrincipal.firstPayment`,
        },
        {
            headerName: "Greitt alls",
            field: `inflationAdjusted_equalPrincipal.totalAmount`,
        },
        {
            headerName: "Greitt alls - Núvirði",
            field: `inflationAdjusted_equalPrincipal.totalAmountPV`,
        },
    ]};
    

    const useCols = isNormal ? normalCols : inflationAdjustedCols;
    const columns = [
        {
            headerName: "Vextir",
            field: 'interestRate',
            sortable: true, filter: true, cellStyle: noStyle, width: "100px",
            pinned: true,
            valueFormatter: (params) => `${params.value} %`,
            minWidth: 100
        },
        {
            headerName: "Jafnar greiðslur",
            children: [...useCols.equalPayment]
            // children: useCols.equalPayment.map(col => ({
            //     ...col,
            //     cellClass: 'jafnar-greidslur-column'
            // }))
        },
        {
            headerName: "Jafnar afborganir",
            children: [...useCols.equalPrincipal]
        },
    ];


    return (
        <div 
            className={`ag-theme-alpine ${classes.grid}`}
            // className='ag-theme-alpine'
            style={{
                height: "50vh",
                width: "100%"
            }}>
            <AgGridReact
                rowData={rowData}
                onFirstDataRendered={onFirstDataRendered}
                defaultColDef={{
                    minWidth: 120,
                    resizable: true,
                    wrapHeaderText: true,
                    autoHeaderHeight: true,
                    valueFormatter: formatThousandSep,
                    // cellStyle: heatmapColorAllData,
                    // cellStyle: heatMapGradient,
                    type: 'numericColumn',
                }}
                onGridReady={onGridReady}
                frameworkComponents={{
                    HtmlHeader: HtmlHeader,
                }}
                columnDefs={columns}
            />
        </div>
    );
};

export default InterestEffectsTable;
