import { Accordion, AccordionDetails, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React from 'react';
import { getLoan1, setLoan1 } from "../../slices/controlSlice";
import LoanTable from "../ag_grid/LoanTable";
import LoanChart from "../charts/LoanChart";
import PaymentSplitChart from "../charts/PaymentSplitChart";
import LoanForm from "../loan_form/LoanForm";
import StyledAccordionSummary from '../partials/StyledAccordionSummary';

import SummaryTable from "../summaryTable/SummaryTable";

const useStyles = makeStyles((theme) => ({
    title: {
        marginBottom: "12px",
        fontSize: "2.5rem",
        ['@media (max-width:1280px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: "2rem",
        },
        ['@media (max-width:940px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: "2rem",
            // marginLeft: "-5em"
        },
        ['@media (max-width:420px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: "2rem",
            // marginLeft: "-2em"
        },
    },
    summary: {
        marginTop: theme.spacing(3),
        // maxWidth: "369px",
        ['@media (max-width:940px)']: { // eslint-disable-line no-useless-computed-key
            marginTop: theme.spacing(5),
        },
    },
    loanInput: {
        marginTop: theme.spacing(3),
        // maxWidth: "420px",
        ['@media (max-width:940px)']: { // eslint-disable-line no-useless-computed-key
            marginTop: theme.spacing(5),
        },
    },
    chart: {
        marginTop: theme.spacing(6),
        // maxHeight: "350px",
        ['@media (max-width:940px)']: { // eslint-disable-line no-useless-computed-key
            // paddingLeft: "-5vw",
            // width: "90vw",
        },
    },
    table: {
        marginTop: theme.spacing(6),
    },
    accordionSummary: {
        marginTop: "0.5 em",
        fontSize: '1.2em',
        borderRadius: '4px',
        fontWeight: 500,

        backgroundColor:
            theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, .05)'
                : 'rgba(0, 0, 0, .03)',
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIcon.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(),
        },

    },
}));

function AdvancedPage() {
    const classes = useStyles();
    const centerContainerProps = { xs: 12, md: 9, xl: 7 }

    return (
        <Grid container justify="center">
            <Grid container item alignItems="center"
                justify="center"
                xs={12} lg={10}
            >

                <Grid item container direction="column" alignItems="center"
                    {...centerContainerProps}
                >
                    {/*<Grid item xs={12}>*/}
                    {/*    <Typography className={classes.title} variant="h3">*/}
                    {/*        Reiknivél fyrir lengra komna*/}
                    {/*    </Typography>*/}
                    {/*</Grid>*/}

                    {/*====== Container for keeping charts square with container above =========*/}
                    <Grid container item
                        direction="column"
                        alignItems="center"
                        justify="center"
                    // spacing={2}
                    >
                        <Grid container item
                            justify="center"

                        >
                            <Grid item style={{ marginTop: "2em", marginBottom: "2em", paddingLeft: "8px" }}
                                xs={12} sm={8} md={12}
                            >
                                <Typography className={classes.title} variant="h1">
                                    Ítarleg lánareiknivél fyrir íbúðalán
                                </Typography>

                                <Typography>
                                    Hér getur þú séð áhrif af aukagreiðslum á höfuðstól láns, hvort sem er
                                    reglulegum eða óreglulegum.
                                </Typography>
                                <Typography>
                                    Þú getur einnig breytt verðbólgu og vöxtum fyrir hvern mánuð, eða fyrir hvert
                                    ár með því að smella á "árleg greiðsluáætlun".
                                </Typography>
                                <Typography>
                                    "Festa upphæð" verður að vera hærri en heildargreiðsla á mánuði til að taka gildi.
                                </Typography>
                                <Typography style={{ marginTop: "0.25em" }}>
                                    Áætlað núvirði miðast alltaf við gefna verðbólgu, en þar sem það er ómögulegt að spá
                                    fyrir um verðbólgu og vexti framtíðar ætti ekki að taka töluna sem heilagan sannleik.
                                </Typography>
                                <Typography>
                                    Hinsvegar getur áætlað núvirði gagnast við samanburð lána,
                                    t.d. verðtryggð eða óverðtryggð lán.
                                </Typography>

                            </Grid>
                        </Grid>

                        {/*====== Loan input form and summary table =========*/}
                        <Grid container item
                            justify="center"
                            xs={12}
                            spacing={2}
                        >
                            <Grid className={classes.loanInput} item xs={12} sm={8} md={6} lg={7}>
                                <LoanForm
                                    title="Lán"
                                    getLoanFun={getLoan1}
                                    setLoanFun={setLoan1}
                                    showAdvanced={true}
                                />
                            </Grid>

                            <Grid className={classes.summary} item xs={12} sm={8} md={6} lg={5}>
                                <SummaryTable
                                    showAdvanced={true}
                                />
                            </Grid>
                        </Grid>



                        {/*====== Charts =========*/}
                        <Grid container item justify="center" style={{ marginBottom: '12px', marginLeft: '8px', marginRight: '8px'}}
                        >
                            <Accordion style={{ width: '100%', marginTop: "1.5em"}} defaultExpanded>
                                <StyledAccordionSummary>
                                    <Typography style={{ textAlign: "center" }}>Þróun höfuðstóls & greiðslubyrðar</Typography>
                                </StyledAccordionSummary>
                                <AccordionDetails>
                                    <Grid container justify="center">
                                        <Grid item className={classes.chart} xs={12} sm={12} md={6}>
                                            <LoanChart
                                                title="Þróun höfuðstóls"
                                                selectKey={"principalBefore"}
                                                hoverLabel="Höfuðstóll"
                                            />
                                        </Grid>
                                        <Grid item className={classes.chart} xs={12} sm={12} md={6}>
                                            <LoanChart
                                                title="Þróun greiðslubyrðar"
                                                selectKey={"totalAmount"}
                                                hoverLabel="Greiðsla"
                                            />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>

                        {/* ====== Pie charts ========= */}
                        <Grid container item justify="center" style={{ marginLeft: '8px', marginRight: '8px'}}>
                            <Accordion style={{ width: '100%' }} defaultExpanded>
                                <StyledAccordionSummary>
                                    <Typography style={{ textAlign: "center" }}>Skipting heildargreiðsla</Typography>
                                </StyledAccordionSummary>
                                <AccordionDetails>
                                    <Grid container>
                                        <Grid item className={`${classes.chart}`} xs={12} sm={12} md={6}>
                                            <PaymentSplitChart
                                                title="Heildargreiðslur"
                                                usePresentValue={false}
                                            />
                                        </Grid>
                                        <Grid item className={classes.chart} xs={12} sm={12} md={6}>
                                            <PaymentSplitChart
                                                title="Heildargreiðslur (núvirði)"
                                                usePresentValue={true}
                                            />
                                        </Grid>
                                    </Grid>
                                </AccordionDetails>

                            </Accordion>
                        </Grid>


                    </Grid>
                </Grid>
            </Grid>

            <Grid className={classes.table} container justify="center">
                <Grid item xs={12} md={10} xl={8}>
                    {/*<Grid xs={12} item>*/}
                    {/*    <Typography variant="h4">Greiðsluáætlun</Typography>*/}
                    {/*</Grid>*/}
                    <Grid xs={12} item>
                        <LoanTable />
                    </Grid>
                    {/*<Grid xs={12} item>*/}
                    {/*    <Typography>*/}
                    {/*        Lánareiknivél*/}
                    {/*    </Typography>*/}
                    {/*</Grid>*/}
                </Grid>
            </Grid>
        </Grid>
    );
}

export default AdvancedPage;
