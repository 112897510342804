import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
    formControl: {
        // margin: theme.spacing(1),
        marginLeft: theme.spacing(1),
        minWidth: 120,
    },
}));

export default function InflationAdjusteSelect(props) {
    const classes = useStyles();

    const {value, changeFun} = props;

    const handleChange = (event) => {
        const val = event.target.value;
        changeFun(val);
    };

    return (
        <div>
            <FormControl variant="outlined" className={classes.formControl}>
                <InputLabel htmlFor="is-inflation-adjusted-select">Tegund</InputLabel>
                <Select
                    native
                    // value={state.age}
                    value={value}
                    onChange={handleChange}
                    label="Payment type"
                    inputProps={{
                        name: 'isInflationAdjusted',
                        id: 'is-inflation-adjusted-select',
                    }}
                >
                    {/*<option aria-label="None" value="" />*/}
                    <option value={"inflationAdjusted"}>Verðtryggt</option>
                    <option value={"normal"}>Óverðtryggt</option>
                </Select>
            </FormControl>
        </div>
    );
}