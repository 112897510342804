import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import NavTabs from "./navigation";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,

    },
    title: {
        flexGrow: 1,
        color: "white",
        textDecoration: "none",
        fontSize: "1.3125rem",
        fontWeight: "500",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        lineHeight: "1.16667em",
        cursor: "pointer",
    },
}));

export default function Navbar() {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <AppBar position="static">
                <Toolbar>
                    <Typography className={classes.title} variant="h6" onClick={() => window.location = "/"}> Lánareiknivél </Typography>
                    <NavTabs />
                </Toolbar>
            </AppBar>
        </div>
    );
}