/**
 * Filter & control product view
 */
import React, {useEffect, useState, useCallback} from 'react';
import {AgGridColumn, AgGridReact} from 'ag-grid-react';


import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import {useSelector} from "react-redux";
import {selectYearlyPaymentPlan1} from "../../selectors/yearlyPaymentPlanSelector";
import "./tables.css"
import {getPaymentPlan1} from "../../slices/controlSlice";
import NavSwitchYearly from "./NavSwitchYearly";


const LoanTable = () => {

    const [displayYearly, setDisplayYearly] = useState(true);
    const [gridApi, setGridApi] = useState(null);

    const dataSelector = displayYearly ? selectYearlyPaymentPlan1 : getPaymentPlan1;

    const rowData = useSelector(dataSelector);

    // const [gridColumnApi, setGridColumnApi] = useState(null);

    const formatter = new Intl.NumberFormat('de-DE');
    const formatThousandSep = function (params) {
        return formatter.format(Math.round(params.value));
    }

    const sizeToFit = useCallback(() => {
        if (gridApi) {
            gridApi.sizeColumnsToFit();
        }
    }, [gridApi]);

    useEffect(() => {
        setTimeout(() => {
            window.addEventListener("resize", sizeToFit);
        });
        return () => window.removeEventListener("resize", sizeToFit);
    }, [sizeToFit]);


    const onGridReady = (params) => {
        setGridApi(params.api);
    };

    const handleFirstDataRender = (params) => {
        sizeToFit();
    }

    const colMetadata = {
        paymentNum: {
            header: displayYearly ? "Ár" : "#"
        },
        monthlyPayment:
        {
            header: displayYearly ? "Meðalgr. á mánuði" : "Heildargr.",
            field: displayYearly ? "averageTotalPayment" : "totalAmount"
        },
    }

    const handleYearChange = (value) => {
        setDisplayYearly(value === 1);
        // setTimeout(sizeToFit, 25);
    };


    useEffect(() => {
        // TODO don't know why the timeout is needed... but it works
        setTimeout(sizeToFit, 25);
    }, [displayYearly, sizeToFit])

    return (
        <>
            <NavSwitchYearly
                changeFun={handleYearChange}
                initialValue={displayYearly === true ? 1 : 0}
            />


            <div className="ag-theme-alpine"
                 style={{
                     height: "50vh",
                 }}>
                <AgGridReact
                    rowData={rowData}
                    onGridReady={onGridReady}
                    onFirstDataRendered={handleFirstDataRender}
                    applyColumnDefOrder={true}
                    defaultColDef={{
                        minWidth: 100,
                        resizable: true
                    }}
                >
                    <AgGridColumn
                        field="paymentNum"
                        headerName={colMetadata.paymentNum.header}
                        initialWidth={60}
                        minWidth={60}
                    />
                    <AgGridColumn
                        field="principalBefore"
                        headerName="Höfuðstóll"
                        valueFormatter={formatThousandSep}
                        minWidth={120}
                    />
                    <AgGridColumn
                        hide={!displayYearly}
                        field="averageTotalPayment"
                        headerName="Meðalgreiðsla á mánuði"
                        valueFormatter={formatThousandSep}
                        minWidth={125}
                    />
                    <AgGridColumn
                        field="totalAmount"
                        headerName="Heildargreiðsla"
                        valueFormatter={formatThousandSep}
                    />
                    <AgGridColumn
                        field="presentValueTotal"
                        headerName="Núvirði heildargreiðslu"
                        valueFormatter={formatThousandSep}
                    />
                    <AgGridColumn
                        field="towardsPrincipal"
                        headerName="Afborgun"
                        valueFormatter={formatThousandSep}
                    />
                    <AgGridColumn
                        field="towardsInterest"
                        headerName="Vextir"
                        valueFormatter={formatThousandSep}
                    />
                    <AgGridColumn
                        field="indexation"
                        headerName="Verðbætur"
                        valueFormatter={formatThousandSep}
                        suppressSizeToFit={false}
                    />
                    {/*<AgGridColumn*/}
                    {/*    field="principalAfter"*/}
                    {/*    headerName="Eftirstöðvar"*/}
                    {/*    valueFormatter={formatThousandSep}*/}
                    {/*    minWidth={120}*/}
                    {/*/>*/}
                </AgGridReact>
            </div>
        </>
    );
};

export default LoanTable;
