/**
 * Filter & control product view
 */
import React from 'react';
import Plotly from 'plotly.js-basic-dist';
import createPlotlyComponent from 'react-plotly.js/factory';
import {getPaymentPlan1} from "../../slices/controlSlice";
import {useSelector} from "react-redux";
import _some from "lodash/some"
import {useDebounce} from "use-debounce"

const Plot = createPlotlyComponent(Plotly);

const LoanChart = (props) => {
    const {title, selectKey, hoverLabel} = props;

    // TODO this works, but why can't I use the debounce from lodash, google later
    const paymentPlan = useSelector(getPaymentPlan1);
    const [debouncedPaymentPlan] = useDebounce(paymentPlan, 400);

    // const myRound = (num, digits) => {
    //     const d = Math.pow(10, 2);
    //     return Math.round(num * d) / d
    // }

    let yVals;
    if (debouncedPaymentPlan) {
        yVals = debouncedPaymentPlan.map(d => d[selectKey]);
    } else yVals = [];

    const shouldDraw = !_some(yVals, Number.isNaN);
    if (!shouldDraw) {
        yVals = Array(yVals.length).fill(0);
    }

    const dataTraces = [
        {
            y: yVals,
            type: 'scatter',
            mode: 'lines',
            marker: {color: 'blue'},
            fill: "tozeroy",
            // TODO correct format
            hovertemplate: `<i>${hoverLabel}</i>: %{y:.4s} kr.`,
            line: {simplify: false},
            name: ""
        }
        // {x: [1, 2, 3], y: [2, 5, 3]}, // add more data to the chart
    ];

    const transition = {
        duration: 300,
        ordering: "traces first",
        easing: "linear-out"
    }

    const titleConfig = {
        text: title,
        font: {
            family: 'Courier New, monospace',
            size: 24
        },
        xref: 'paper',
        x: 0.025,
        y: 0.95
    };

    const xAxisConfig = {
        title: {
            text: 'Greiðsla nr.',
            font: {
                family: 'Courier New, monospace',
                size: 18,
                color: '#7f7f7f',
            },
            standoff: 10,
        }
    };

    return (
        <>
            <Plot
                data={dataTraces}
                layout={{
                    margin: {
                        t: 50, r: 20, b: 40, l: 40,
                        pad: 5
                    },
                    height: 350,
                    separators: ',.',
                    transition: transition,
                    title: titleConfig,
                    xaxis: xAxisConfig
                }}
                config={{displayModeBar: false}}
                useResizeHandler={true}
                style={{width: "100%", height: "100%"}}
            />
        </>
    );
}

export default LoanChart
