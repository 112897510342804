/**
 * Filter & control product view
 */
import React from 'react';
import {Grid, Typography} from "@material-ui/core";
import NumberInput from "./NumberInput";
import {useDispatch, useSelector} from "react-redux";
import PaymentTypeSelect from "./PaymentTypeSelect";
import InflationAdjustedSelect from "./InflationAdjustedSelect";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    title: {
        ['@media (max-width:1280px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: "2rem",
        },
        ['@media (max-width:940px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: "1.5rem",
        },
    },
    mainContainer: {
        height: "100%"
    },
    loanType: {
        ['@media (max-width:940px)']: { // eslint-disable-line no-useless-computed-key
            // marginTop: theme.spacing(1.5)
        }
    },
}));

const LoanForm = (props) => {
    const {title, getLoanFun, setLoanFun, showAdvanced} = props;
    const classes = useStyles();
    const dispatch = useDispatch();

    // console.log("isSimple:", showAdvanced);
    const loan = useSelector(getLoanFun);

    // useEffect(() => {
    //     console.log("RENDERING LoanForm object");
    // })
    //
    // useEffect(() => {
    //     console.log("====== Mounting LoanForm");
    // }, []);

    // const showAdvanced = true;

    const inputSpacing = 1;

    return (
        <>
        <Typography className={classes.title} variant="h4">{title}</Typography>
        <Grid className={classes.mainContainer} container
              item
              alignItems="flex-start"
              direction="column"
              spacing={3}
              lg={11} xl={10}

        >
            <Grid container item
                // justify="space-between"
                  spacing={inputSpacing}
            >
                <Grid item xs={6}>
                    <NumberInput
                        // key={1}
                        label="Upphæð"
                        adornment="kr."
                        value={loan.principal}
                        changeFun={(val) => {
                            // val = formatThousandSep(val)
                            dispatch(setLoanFun({"principal": val}))
                        }
                    }
                />
            </Grid>
            <Grid item xs={6}>
                <NumberInput
                    label="Lánstími"
                    adornment="ár"
                    value={loan.nYears}
                    // min={1}
                    changeFun={(val) => dispatch(setLoanFun({"nYears": val}))}
                />
            </Grid>
            <Grid item xs={6}>
                <NumberInput
                    label="Vextir"
                    adornment="%"
                    value={loan.interest}
                    changeFun={(val) => dispatch(setLoanFun({"interest": val}))}
                />
            </Grid>
            <Grid item xs={6}>
                <NumberInput
                    label="Verðbólga"
                    adornment="%"
                    value={loan.inflation}
                    changeFun={(val) => dispatch(setLoanFun({"inflation": val}))}
                />
            </Grid>

            {showAdvanced &&
            <>
                <Grid item xs={6}>
                    <NumberInput
                        label="Aukageiðsla"
                        adornment="kr."
                        min={0}
                        value={loan.monthlyExtraPayment}
                        changeFun={(val) => dispatch(setLoanFun({"monthlyExtraPayment": val}))}
                    />
                </Grid>
                <Grid item xs={6}>
                    <NumberInput
                        label="Festa upphæð"
                        adornment="kr."
                        value={loan.monthlyStaticPayment}
                        changeFun={(val) => dispatch(setLoanFun({"monthlyStaticPayment": val}))}
                    />
                </Grid>
                <Grid item xs={6}>
                    <NumberInput
                        label="Greiðslugjald"
                        adornment="kr."
                        value={loan.processingFee}
                        changeFun={(val) => dispatch(setLoanFun({"processingFee": val}))}
                    />
                </Grid>
            </>
            }

        </Grid>

        <Grid container item
            // justify="space-between"
              spacing={inputSpacing}
        >
            <Grid item xs={6} sm={6}>
                <PaymentTypeSelect
                    value={loan.paymentType}
                    changeFun={(val) => dispatch(setLoanFun({"paymentType": val}))}
                />
            </Grid>

            <Grid item xs={6} sm={6} className={classes.loanType}>
                <InflationAdjustedSelect
                    value={loan.loanType}
                    changeFun={(val) => dispatch(setLoanFun({"loanType": val}))}
                />
            </Grid>
        </Grid>
        </Grid>

</>
)
    ;
}

export default LoanForm;
