import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import React from 'react';
import { getLoan1, setLoan1 } from "../../slices/controlSlice";

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { useDispatch, useSelector } from "react-redux";
import InterestEffectsTable from "../ag_grid/InterestEffectsTable";
import InflationAdjustedSelect from "../loan_form/InflationAdjustedSelect";
import NumberInput from "../loan_form/NumberInput";
// import utils from "../"
import debounce from 'lodash/debounce';
import PaymentTypeSelect from "../loan_form/PaymentTypeSelect"




const useStyles = makeStyles((theme) => ({
    title: {
        marginBottom: "12px",
        fontSize: "2.5rem",
        ['@media (max-width:1280px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: "2rem",
        },
        ['@media (max-width:940px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: "2rem",
            // marginLeft: "-5em"
        },
        ['@media (max-width:420px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: "2rem",
            // marginLeft: "-2em"
        },
    },
    titleH2: {
        marginBottom: "8px",
        fontSize: "2rem",
        ['@media (max-width:1280px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: "1.5rem",
        },
        ['@media (max-width:940px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: "1.5rem",
            // marginLeft: "-5em"
        },
        ['@media (max-width:420px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: "1.5rem",
            // marginLeft: "-2em"
        },
    },
    summary: {
        marginTop: theme.spacing(2),
        // maxWidth: "369px",
        ['@media (max-width:940px)']: { // eslint-disable-line no-useless-computed-key
            marginTop: theme.spacing(5),
        },
    },
    loanInput: {
        marginTop: theme.spacing(2),
        // maxWidth: "420px",
        ['@media (max-width:940px)']: { // eslint-disable-line no-useless-computed-key
            marginTop: theme.spacing(5),
        },
    },
    table: {
        marginTop: theme.spacing(7),
    },
}));


const LoanFormCustom = (props) => {
    const { title, getLoanFun, setLoanFun, showAdvanced } = props;
    const classes = useStyles();
    const dispatch = useDispatch();

    const loan = useSelector(getLoanFun);

    const inputSpacing = 1;

    const debouncedChangeFun = debounce((val) => {
        dispatch(setLoanFun({ "nYears": val }));
    }, 225);  //

    return (
        <>
            <Typography className={classes.titleH2} variant="h2">{title}</Typography>
            <Grid className={classes.mainContainer} container
                item
                alignItems="flex-start"
                direction="column"
                spacing={3}
                lg={11} xl={10}

            >
                <Grid container item
                    // justify="space-between"
                    spacing={inputSpacing}
                >
                    <Grid item xs={6}>
                        <NumberInput
                            // key={1}
                            label="Upphæð"
                            adornment="kr."
                            value={loan.principal}
                            changeFun={(val) => {
                                // val = formatThousandSep(val)
                                dispatch(setLoanFun({ "principal": val }))
                            }
                            }
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <NumberInput
                            label="Lánstími"
                            adornment="ár"
                            value={loan.nYears}
                            max={99}
                            // min={1}
                            // changeFun={(val) => dispatch(setLoanFun({ "nYears": clamp(val, 0, 99) }))}
                            // changeFun={(val) => dispatch(setLoanFun({ "nYears": val}))}
                            changeFun={debouncedChangeFun}
                        />
                    </Grid>
                    <Grid item xs={6} style={{ width: '100%' }}>
                        <NumberInput
                            label="Verðbólga"
                            adornment="%"
                            value={loan.inflation}
                            changeFun={(val) => dispatch(setLoanFun({ "inflation": val }))}
                        />
                    </Grid>
                    <Grid item xs={6} className={classes.loanType} style={{ width: '100%' }}>
                        <InflationAdjustedSelect
                            value={loan.loanType}
                            changeFun={(val) => dispatch(setLoanFun({ "loanType": val }))}
                        />
                    </Grid>
                </Grid>
            </Grid>

        </>
    )
        ;
}



function InterestEffectPage() {
    const classes = useStyles();
    const centerContainerProps = { xs: 12, md: 9, xl: 7 }
    // const centerContainerProps = {}

    // const availableFields = [
    //     { value: 'firstPayment', displayText: 'Fyrsta greiðsla', shortDisplayText: 'FP' },
    //     { value: 'totalAmount', displayText: 'Greitt alls', shortDisplayText: 'TA' },
    //     { value: 'totalAmountPV', displayText: 'Geitt alls \n núvirði', shortDisplayText: 'TAPV' }
    // ]


    // const [selectedField, setSelectedField] = useState(availableFields[0]);


    const loanInfoBase = useSelector(getLoan1);

    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
  

    const isMobile = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Grid container justify="center">
            <Grid container item alignItems="center"
                justify="center"
                xs={12} lg={10}
            >

                <Grid item container direction="column"
                    {...centerContainerProps}
                    // justify="center"
                >
                    {/*====== Container for keeping charts square with container above =========*/}
                    <Grid container item
                        direction="column"
                        alignItems="center"
                        justify="center"
                    // spacing={2}
                    >
                        <Grid container item
                            justify="center"
                            style={{ marginBottom: '12px', marginLeft: '8px', marginRight: '8px' }}

                        >
                            <Grid item style={{ marginTop: "0.5em", marginBottom: "0.25em", paddingLeft: "8px" }}
                                xs={12} sm={8} md={12}
                            >
                                <Typography className={classes.title} variant="h1">
                                    Áhrif vaxta á lán
                                </Typography>

                                <Typography>
                                    Hér getur þú séð hvaða áhrif mismunandi vextir hafa á þitt lán.
                                </Typography>
                                <Typography>
                                    Athugaðu að bæði vextir og verðbólga hafa mikil áhrif á lánið, og geta breyst mikið yfir lánstímann.
                                    En þessi reiknivél gerir ráð fyrir fastri verðbólgu og föstum vöxtum út allan lánstímann.
                                </Typography>
                            </Grid>
                        </Grid>


                        {/*====== Loan input form and summary table =========*/}
                        <Grid container item
                            justify="center"
                            xs={12}
                            spacing={2}
                            // sx={{ mb: 2 }}
                            style={{ marginBottom: '12px'}}
                        >
                            <Grid
                                className={classes.loanInput}
                                item xs={12} sm={8} md={6} lg={5}
                                // order={isMobile ? 2 : 1}  // Set order based on screen size
                                style={{ order: isMobile ? 2 : 1,
                                 }}  // Set order using inline styles


                            >
                                <LoanFormCustom
                                    title="Lán"
                                    getLoanFun={getLoan1}
                                    setLoanFun={setLoan1}
                                    showAdvanced={false}
                                    style={{
                                    }}
                                />
                            </Grid>

                            <Grid
                                className={classes.summary}
                                item xs={12} sm={8} md={6} lg={7}
                                // order={isMobile ? 1 : 2}  // Set order based on screen size
                                style={{ order: isMobile ? 1: 2,
                                    // marginRight: isMobile ? "0.5em" : "0.5em"
                                }}  // Set order using inline styles

                            >
                                <Typography className={classes.titleH2} variant="h2">
                                    Leiðbeiningar
                                </Typography>

                                <Typography>
                                    Sláðu inn stöðuna á þínu láni eins og það stendur í dag
                                    og sjáðu hvernig lánið breytist m.v. mismunandi vexti. 
                                </Typography>
                                {/* <Typography>
                                    Þá getur valið að sjá 
                                </Typography> */}
                            </Grid>
                        </Grid>



                        <Grid container item
                            justify="center"
                            xs={12}
                        >
                            {/* <Box mb={2}
                                style={{ width: "100%" }}
                            >
                                <FieldSelector
                                    fields={availableFields}
                                    onChange={selectedField => {
                                        setSelectedField(selectedField);
                                    }}
                                />
                            </Box> */}
                            <InterestEffectsTable 
                                loanInfoBase={loanInfoBase} 
                                // selectedField={selectedField.value}
                            />
                            {/* </Grid> */}
                        </Grid>


                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default InterestEffectPage;
