/**
 * Filter & control product view
 */
import React from 'react';


import 'ag-grid-community/dist/styles/ag-grid.css';
// import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';

import {useSelector} from "react-redux";
import {makeStyles} from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import utils from "../../javascripts/utils";
import {selectSummaryTableData} from "../../selectors/summaryTableSelector";
import SummaryTableItem from "./SummaryTableItem";
import {Divider} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        // flexGrow: 1,
        // maxWidth: 350,
    },
    demo: {
        backgroundColor: theme.palette.background.paper,
    },
    title: {
        margin: theme.spacing(2, 0, 0),
    },
}));


const SummaryTable = (props) => {
    const {showAdvanced} = props;

    const classes = useStyles();
    const summary = useSelector(selectSummaryTableData);

    const formatter = new Intl.NumberFormat('de-DE');
    const formatThousandSep = function (value) {
        if (utils.isNumber(value)) {
            value = Math.round(value)
            if (value === -0) value = 0;
            return formatter.format(value);
        } else {
            return value
        }
    }

    const formatMonths = (val) => {
        let nMonths = val;
        let nYears = nMonths / 12;
        const leftOver = nYears - Math.floor(nYears);

        nMonths = Math.round(leftOver * 12);
        nYears = Math.floor(nYears);

        return nYears + " ár " + nMonths + " mán";
    }

    return (
        <div className={classes.root}>
            <Typography variant="h6" className={classes.title}>
                Samantekt
            </Typography>
            <div className={classes.demo}>
                <List
                    dense={true}
                >
                    <SummaryTableItem
                        label="Greitt alls"
                        value={formatThousandSep(summary.totalAmount)}
                        styleOverride={{fontWeight: "bold"}}
                    />
                    <SummaryTableItem
                        label="Núvirði alls"
                        value={formatThousandSep(summary.totalPresentValue)}
                        styleOverride={{fontWeight: "bold"}}
                    />
                    <Divider/>
                    <SummaryTableItem
                        label="Vextir alls"
                        value={formatThousandSep(summary.totalTowardsInterest)}
                    />
                    <SummaryTableItem
                        label="Verðbætur alls"
                        value={formatThousandSep(summary.totalIndexation)}
                    />

                    {showAdvanced &&
                    <>
                        <SummaryTableItem
                            label="Afgreiðslugjöld alls"
                            value={formatThousandSep(summary.totalProcessingFee)}
                        />
                        <SummaryTableItem
                            label="Aukagreiðslur alls"
                            value={formatThousandSep(summary.totalExtraPayments)}
                        />
                        <SummaryTableItem
                            label="Fjöldi greiðslna"
                            value={summary.nPayments}
                        />
                        <SummaryTableItem
                            label="Sparnaður"
                            value={formatThousandSep(summary.totalSaved)}
                        />
                        <SummaryTableItem
                            label="Sparnaður (núvirði)"
                            value={formatThousandSep(summary.totalSavedPV)}
                        />
                        <SummaryTableItem
                            label="endurgreiðslutími"
                            value={formatMonths(summary.nPayments)}
                        />
                    </>
                    }
                    {!showAdvanced &&
                        <>
                            <SummaryTableItem
                                label="Fyrsta greiðsla"
                                value={formatThousandSep(summary.firstPaymentAmount)}
                            />
                            <SummaryTableItem
                                label="Síðasta greiðsla"
                                value={formatThousandSep(summary.lastPaymentAmount)}
                            />
                        </>
                    }
                </List>
            </div>
        </div>
    );
}

export default SummaryTable;
