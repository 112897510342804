import {createSelector} from "@reduxjs/toolkit";
import {getLoan1, getPaymentOverride, getPaymentPlan1} from "../slices/controlSlice";
import _sumBy from "lodash/sumBy"
import utils from "../javascripts/utils";
// import React from 'react'


export const selectSummaryTableData = createSelector(
    [
        getPaymentPlan1,
        getLoan1,
        getPaymentOverride
    ],
    (paymentPlan, loan1, paymentOverrides) => {

        const sumTotal = _sumBy(paymentPlan, d => d.totalAmount);
        const sumTotalPV = _sumBy(paymentPlan, d => d.presentValueTotal);
        const sumExtraPayment = _sumBy(paymentPlan, d => d.extraPayment);

        // calculate repayment plan assuming no extra payments
        // for comparison
        const loanBase = {...loan1};
        loanBase.monthlyExtraPayment = 0;
        loanBase.monthlyStaticPayment = 0;

        // Need overrides base in case interest/indexation has been changed
        // but do not want extra payments for the base val
        const overridesBase = paymentOverrides.map(d => {return {...d}});
        overridesBase.forEach (d => {
            if (d.hasOwnProperty("extraPayment"))
                d.extraPayment = 0;
        });

        const basePaymentPlan = utils.calcPaymentPlan(loanBase, overridesBase);
        const sumTotalBase = _sumBy(basePaymentPlan, d => d.totalAmount);
        const sumTotalPVBase = _sumBy(basePaymentPlan, d => d.presentValueTotal);

        let res = {
            totalAmount: sumTotal,
            totalPresentValue: sumTotalPV,
            totalTowardsInterest:_sumBy(paymentPlan, d => d.towardsInterest),
            totalIndexation:_sumBy(paymentPlan, d => d.indexation),
            totalTowardsPrincipal:_sumBy(paymentPlan, d => d.towardsPrincipal + d.extraPayment),
            totalProcessingFee:_sumBy(paymentPlan, d => d.processingFee),
            totalExtraPayments:sumExtraPayment,
            nPayments:paymentPlan.filter(d => d.totalAmount > 0).length,
            totalSaved:sumTotalBase - sumTotal,
            totalSavedPV:sumTotalPVBase - sumTotalPV,
            repaymentTime: paymentPlan.filter(d => d.totalAmount > 0).length,
            firstPaymentAmount:paymentPlan[0]?.totalAmount,
            lastPaymentAmount:paymentPlan[paymentPlan.length - 1]?.totalAmount,

            totalIndexationPV: _sumBy(paymentPlan, d => d.indexation / d.cumulativeCompoundedInflation),
            totalInterestAndFeesPV: _sumBy(paymentPlan, d =>
                (d.towardsInterest + d.processingFee) / d.cumulativeCompoundedInflation
            ),
            totalPrincipalPV: _sumBy(paymentPlan, d => d.towardsPrincipal / d.cumulativeCompoundedInflation),

        }
        return res;
    }
);

