import React from 'react';
import Navbar from "./components/navbar/Navbar";
import {makeStyles} from "@material-ui/core/styles";
import AdvancedPage from "./components/pages/advanced";
import InterestEffectPage from "./components/pages/InterestEffectPage";
import {Route, Switch} from "react-router-dom";
import SimplePage from "./components/pages/simple";

// import Logger from "js-logger"
// import './App.css';

const useStyles = makeStyles((theme) => ({
    footer: {
        textAlign: "center",
        backgroundColor: theme.palette.background.paper,
        paddingBottom: "4em"
    },
    footerText: {
        padding: theme.spacing(2),
        color: "rgba(0, 0, 0, 0.4)",
        fontSize: "1rem",
        fontWeight: "500",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        lineHeight: "1.75",
        letterSpacing: "0.00938em"
    },
    footerLink: {
        color: "rgba(0, 0, 0, 0.3)"
    },
    footerLine: {
        margin: "0px",
    },
}));

function App() {
    // const dispatch = useDispatch();
    // const loan1 = useSelector(getLoan1)
    // Logger.useDefaults();
    // Logger.setLevel(Logger.DEBUG);
    const classes = useStyles();

    return (
        <div>
            <Navbar/>
            <header className="App-header" style={{marginBottom: "3em"}}>
                {/*<Typography variant="h4"*/}
                {/*            style={{background: "rgba(250, 245, 10, 0.3)"}}>Síðan er í*/}
                {/*    vinnslu</Typography>*/}
            </header>

            <Switch>
                <Route exact path="/" render={(props) => <SimplePage {...props} />}/>
                <Route exact path="/advanced" render={(props) => <AdvancedPage {...props} />}/>
                <Route exact path="/vextir-ahrif" render={(props) => <InterestEffectPage {...props} />}/>
                {/*<Route exact path="/compare" render={(props) => <About {...props} />} />*/}
            </Switch>

            <footer className={classes.footer}>
                <h5 className={classes.footerText}>
                    <p className={classes.footerLine}>Lánareiknivél</p>
                    <p className={classes.footerLine}>[Búið til af Vefurunum E&E]</p>
                    <p className={classes.footerLine}><a className={classes.footerLink}
                                                         href="mailto:samband@lanareiknivel.is">Hafðu samband </a></p>

                </h5>
            </footer>
        </div>
    )
        ;
}

export default App;
